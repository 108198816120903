import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery, withPrefix } from "gatsby";

import "./Layout.module.css";
import { LayoutQuery } from "../../graphql-types";

// Shared, global layout component, as described in
// https://www.gatsbyjs.com/docs/how-to/styling/global-css/#adding-global-styles-with-a-layout-component
const Layout: React.FunctionComponent = ({ children }) => {
  const data: LayoutQuery = useStaticQuery(graphql`
    query Layout {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        <title>{data.site?.siteMetadata?.title}</title>

        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="description"
          content={data.site?.siteMetadata?.description}
        />

        <meta
          property="og:description"
          content={data.site?.siteMetadata?.description}
        />
        <meta property="og:title" content={data.site?.siteMetadata?.title} />

        <script src={withPrefix("js/sparkles.js")} type="text/javascript" />
      </Helmet>
      {children}
    </>
  );
};

export default Layout;
