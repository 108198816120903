import { PageProps } from "gatsby";
import React, { FunctionComponent } from "react";

import Layout from "../components/Layout";
import * as style from "./index.module.css";

const Index: FunctionComponent<PageProps> = (props) => {
  return (
    <Layout>
      <main className={style.main}>
        <div className={style.outer}>
          <h1>
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              clipRule="evenodd"
            >
              <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm-.045 17.51h-.015c-2.285 0-4.469-1.189-6.153-3.349l.789-.614c1.489 1.911 3.394 2.963 5.364 2.963h.013c1.987-.004 3.907-1.078 5.408-3.021l.791.611c-1.693 2.194-3.894 3.405-6.197 3.41zm-3.468-10.01c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm7.013 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z" />
            </svg>{" "}
            hello
          </h1>
          <div className={style.inner}>
            <p>I'm Sara (she/they).</p>
            <p>
              I program computers. I've written code for <a href="https://slack.com">Slack</a>,{" "}
              <a href="https://vitess.io">Vitess</a>,{" "}
              <a href="https://turbinelabs.io">Turbine Labs</a>,{" "}
              <a href="https://plot.ly">plotly</a>, and{" "}
              <a href="https://twitter.com">Twitter</a>.
            </p>
            <p>
              I like drawing, playing the banjo, and making useful things for
              people.
            </p>

            <p>
              I also exist in some form on{" "}
              <a href="https://twitter.com/doeg">Twitter</a> and{" "}
              <a href="https://github.com/doeg">GitHub</a>.
            </p>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Index;
